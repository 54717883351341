import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const heroStyles = {
  backgroundImage: "url(/images/developers-bg.jpg)",
  backgroundSize: "cover",
  backgroundPosition: "center",
  minHeight: "30vh",
}

const sectionStyles = {
  padding: "0",
  // paddingTop: "3rem",
  // backgroundColor: "#082D5E",
}

const columnsStyles = {
  marginBottom: "0",
}

const backgroundImageStyles = {
  backgroundImage: "url(/images/developers-img.jpg)",
  backgroundSize: "cover",
  backgroundPosition: "center",
  minHeight: "30vh",
}

const contentStyles = {
  maxWidth: "720px",
}

const Developers: React.FC = () => {
  return (
    <>
      <section className="hero is-medium" style={heroStyles} >
        <div className="hero-body">
          &nbsp;
        </div>
      </section>
      <section id="developers" className="section" style={sectionStyles}>
        <div className="columns" style={columnsStyles}>
          <div className="column is-6-desktop is-4-tablet is-hidden-mobile" style={backgroundImageStyles}>
            <p>&nbsp;</p>
          </div>
          <div className="column is-6-desktop is-8-tablet">
            {/* <StaticImage src="../images/developers.svg" alt="developers" width={800} /> */}
            <div className="p-6 content has-text-dark" style={contentStyles}>
              <h1 className="title is-1 has-text-weight-light">
                DEVELOPERS
              </h1>
              <p>
                At Consigma Estates, we specialise in identifying and unlocking the potential of land and properties to help property developers like you maximize their returns. We understand that finding the right land and property for your development can be a challenge, which is why we make it easy for you to get in touch with us.
              </p>
              <p>
                If you're a property developer looking for the perfect site for your next project, we encourage you to get in touch with us. We deal directly with landowners, and our acquisitions are exclusive and cannot be found elsewhere on the market. This means that we have access to a wide range of properties that may not be available through other channels.
              </p>
              <p>
                When you work with us, you can be confident that you're getting access to the best possible sites for your development. Our team of experts will work closely with you to understand your specific requirements and help you find the perfect location for your project. We'll handle all of the negotiations with the landowner and local authorities, so you can focus on what you do best - developing exceptional properties.
              </p>
              <p>
                At Consigma Estates, we are committed to helping property developers like you achieve success in the UK property market. Our team has a deep understanding of the planning process and the complexities that come with it, and we have a proven track record of delivering exceptional results for our clients.
              </p>
              <p>
                So why wait? Get in touch with us today to discuss your land requirements and learn more about how we can help you unlock the full potential of your development. We look forward to hearing from you soon!
              </p>
            </div>
          </div>
          <div className="column is-6-desktop is-4-tablet is-hidden-tablet" style={backgroundImageStyles}>
            &nbsp;
          </div>
        </div>
      </section>
    </>
  )
};

export default Developers;
